exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-book-tsx": () => import("./../../../src/pages/book.tsx" /* webpackChunkName: "component---src-pages-book-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-partners-tsx": () => import("./../../../src/pages/partners.tsx" /* webpackChunkName: "component---src-pages-partners-tsx" */),
  "component---src-pages-policy-tsx": () => import("./../../../src/pages/policy.tsx" /* webpackChunkName: "component---src-pages-policy-tsx" */),
  "component---src-pages-product-tsx": () => import("./../../../src/pages/product.tsx" /* webpackChunkName: "component---src-pages-product-tsx" */),
  "component---src-pages-team-tsx": () => import("./../../../src/pages/team.tsx" /* webpackChunkName: "component---src-pages-team-tsx" */)
}

